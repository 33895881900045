import React, { useEffect, useState } from "react"
import "./assets/styles/_index.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Carousel from "react-bootstrap/Carousel"
import { Link } from "@reach/router"
import { ArrowLeft } from "react-bootstrap-icons"
import configs from "../../Utils/configs"
import { postAPICall } from "../../Utils/Network"
import Swal from "sweetalert2"
import "bootstrap/dist/css/bootstrap.min.css"
import LoadingModal from "../loading/LoadingModal"

function ExpoBooths() {
  const [index, setIndex] = useState(0)
  const [itemSet1, setItemValues1] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false) 


  // const ExpoIcon = configs.UTILITY_URL + "/informationdesk/Expo_Icon.png"
  const HomeIcon = configs.UTILITY_URL + "/informationdesk/Home_icon_hall.png"
  const InfodeskIcon =
    configs.UTILITY_URL + "/informationdesk/Infodesk_icon_hall.png"
  const backgroundViewImage = configs.UTILITY_URL + "/expobooths/booths-bg.jpg"
  const logo = configs.UTILITY_URL + "/expobooths/logo.png"

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  useEffect(() => {
    FetchDetails();
    setIsInitialized(true) 

  }, [])

  const FetchDetails = () => {
    const url = configs.DEV_PROJ_API_URL()
    const param = {
      DeveloperName: "",
    }

    postAPICall(url, param, res => {
      if (res && res.Status === "success") {

      if (res && res.Data && res.Data.DeveloperDetails) {
        const BoothImages = res.Data.BoothImages
        const sortedImages = BoothImages.sort(
          (a, b) => a.SortOrder - b.SortOrder
        )
        const developerImages = sortedImages.map(image => ({
          src: `${configs.UTILITY_URL}/${image.DeveloperId}/${image.FileName}`,
          link: `/developers/${image.RouteName}/`,
          sortOrder: image.SortOrder,
        }))
        setItemValues1(developerImages)
      }
      setIsLoading(false)
            };
    })
  }

  const ComingSoonClick = () => {
    Swal.fire({
      title: "<strong>New Booths <u>Opening Soon...</u></strong>",
      icon: "success",
      html: `<b>Thanks To Reach us....</b>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: `<i class="fa fa-thumbs-up"></i> Ok`,
    })
  }

  const BackGroundImage = {
    backgroundImage: `url(${backgroundViewImage})`,
  }

   if (!isInitialized) {
    return null
  }

  const splitIntoChunks = (array, chunkSize) => {
    const result = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }
    return result
  }

  const CustomCarousel = () => {
    const chunkedItems = splitIntoChunks(itemSet1, 5)

    return (
      <>
      <LoadingModal isLoading={isLoading} />
        <Container fluid>
          <Carousel
            slide={true}
            fade={true}
            indicators={false}
            activeIndex={index}
            className="BoothSectionSlider"
            onSelect={handleSelect}
            interval={3000}
          >
            {chunkedItems.map((group, groupIndex) => (
              <Carousel.Item key={groupIndex} className="carousel-item">
                <Row>
                  {group.length >= 1 && (
                    <Col
                      lg={12}
                      className="p-0 textAlignCenter textAlignLeftMob"
                    >
                      <Link to={group[0].link}>
                        <Image
                          src={group[0].src}
                          fluid
                          className="LogoSectionBooths"
                          alt="image"
                        />
                      </Link>
                    </Col>
                  )}
                  {group.length >= 2 && (
                    <Col lg={6} className="p-0 textAlignRight">
                      <Link to={group[1].link}>
                        <Image
                          src={group[1].src}
                          fluid
                          className="LogoSectionMiddle"
                          alt="image"
                        />
                      </Link>
                    </Col>
                  )}
                  {group.length >= 3 && (
                    <Col lg={6} className="p-0 textAlignLeft">
                      <Link to={group[2].link}>
                        <Image
                          src={group[2].src}
                          fluid
                          className="LogoSectionMiddle"
                          alt="image"
                        />
                      </Link>
                    </Col>
                  )}
                  {group.length >= 4 && (
                    <Col
                      lg={6}
                      className="p-0 textAlignCenter textAlignRightMob"
                    >
                      <Link to={group[3].link}>
                        <Image
                          src={group[3].src}
                          fluid
                          className="LogoSectionList"
                          alt="image"
                        />
                      </Link>
                    </Col>
                  )}
                  {group.length >= 5 && (
                    <Col
                      lg={6}
                      className="p-0 textAlignCenter textAlignLeftMob"
                    >
                      <Link to={group[4].link}>
                        <Image
                          src={group[4].src}
                          fluid
                          className="LogoSectionList"
                          alt="image"
                        />
                      </Link>
                    </Col>
                  )}
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </>
    )
  }

  return (
    <>
      <div className="BoothSection" style={BackGroundImage}>
        <Container fluid>
          <Row>
            <Col lg={4} className="textAlignLeft BoothBackSection">
              <Link to="/" className="d-none">
                <ArrowLeft /> Back
              </Link>
            </Col>
            <Col lg={4} className="textAlignCenters">
              <Link to="/">
                <Image src={logo} fluid className="LogoSection" alt="image"/>
              </Link>
            </Col>
            <Col lg={4} className="textAlignCenter"></Col>
          </Row>
        </Container>

        <CustomCarousel />
      </div>

      <Container fluid className="ProjectAboutMiddleSectionTop">
        <div className="ProjectTopSectionexpo">
          <ul>
            <li>
              <Link to="/">
                <Image src={HomeIcon} fluid className="homeicon" alt="image"/>
                <br />
                <span>Home</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/exhibition-hall/">
                <Image src={ExpoIcon} fluid className="hallicon" />
                <br />
                <span>Hall</span>
              </Link>
            </li> */}
            <li>
              <Link to="/information-desk/">
                <Image src={InfodeskIcon} fluid className="infoicon" alt="image"/>
                <br />
                <span>Info</span>
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    </>
  )
}

export default ExpoBooths
